"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPolygonArea = exports.getPolylineArea = exports.doPointInPolygon = exports.getIndicesForLinePairs = exports.getIndicesForLineGeometry = exports.getInnerEdgeOfPolygon = exports.getCircle = exports.scaleGeometry = exports.getBoxWidthAndDepth = exports.vector2ToVector3 = exports.toVector2 = exports.bufferSegment = exports.getPerpendicularDirection = exports.moveTowards = void 0;
const THREE = __importStar(require("three"));
const three_1 = require("three");
function moveTowards(from, to, distance) {
    // @ts-ignore
    return from.clone().add(to.clone().sub(from).normalize().multiplyScalar(distance));
}
exports.moveTowards = moveTowards;
function getPerpendicularDirection(lineSegemnt, isCcw = false) {
    // @ts-ignore
    const dir = lineSegemnt[1].clone().sub(lineSegemnt[0]).normalize();
    return isCcw ? new THREE.Vector2(-dir.y, dir.x) : new THREE.Vector2(dir.y, -dir.x);
}
exports.getPerpendicularDirection = getPerpendicularDirection;
const bufferSegment = (segment, extrudeOutwards, extrudedInwards) => {
    let segment2;
    if (segment[0].isVector3) {
        segment2 = toVector2(segment);
    }
    else {
        segment2 = segment;
    }
    const perpendicularOutwards = getPerpendicularDirection(segment2).multiplyScalar(extrudeOutwards);
    const perpendicularInwards = getPerpendicularDirection(segment2).multiplyScalar(extrudedInwards || 0);
    const points = [
        segment2[0].clone().sub(perpendicularInwards),
        segment2[1].clone().sub(perpendicularInwards),
        segment2[1].clone().add(perpendicularOutwards),
        segment2[0].clone().add(perpendicularOutwards),
    ];
    points.push(points[0]);
    return points;
};
exports.bufferSegment = bufferSegment;
function toVector2(list) {
    if (!list || !list.length) {
        return [];
    }
    const to2 = (v) => new THREE.Vector2(v.x, v.y);
    if (Array.isArray(list)) {
        if (!list[0].isVector3) {
            return list;
        }
        return list.map((v) => to2(v));
    }
    if (!list.isVector3) {
        return list;
    }
    return to2(list);
}
exports.toVector2 = toVector2;
const vector2ToVector3 = (list, z = 0) => {
    const to3 = (v, z = 0) => new THREE.Vector3(v.x, v.y, z);
    if (Array.isArray(list)) {
        return list.map((v) => to3(v, z));
    }
    return to3(list);
};
exports.vector2ToVector3 = vector2ToVector3;
function getBoxWidthAndDepth(coords) {
    // @ts-ignore
    const dist1 = coords[0].distanceTo(coords[1]);
    // @ts-ignore
    const dist2 = coords[1].distanceTo(coords[2]);
    return { width: Math.min(dist1, dist2), depth: Math.max(dist1, dist2) };
}
exports.getBoxWidthAndDepth = getBoxWidthAndDepth;
const scaleGeometry = (geometry, scaleFactor) => {
    const vecCenter = new THREE.Vector3();
    const box = new THREE.Box3();
    if (geometry instanceof THREE.BufferGeometry) {
        box.setFromBufferAttribute(geometry.attributes.position);
    }
    box.getCenter(vecCenter);
    geometry.translate(-vecCenter.x, -vecCenter.y, -vecCenter.z);
    geometry.scale(scaleFactor, scaleFactor, scaleFactor);
    geometry.translate(vecCenter.x, vecCenter.y, vecCenter.z);
    return geometry;
};
exports.scaleGeometry = scaleGeometry;
const getCircle = (point, radius, fromAngle, toAngle) => {
    if (toAngle < fromAngle) {
        console.warn('toAngle has to be larger then fromAngle');
        return [];
    }
    const CIRCLE_SEGMENTS = 32;
    const vertices = [];
    for (let i = fromAngle; i < toAngle; i += (toAngle - fromAngle) / CIRCLE_SEGMENTS) {
        const x = Math.sin(i) * radius;
        const y = Math.cos(i) * radius;
        vertices.push(point.clone().add(new THREE.Vector2(x, y)));
    }
    return vertices;
};
exports.getCircle = getCircle;
const getInnerEdgeOfPolygon = (curve, polygon) => {
    const THRESHOLD = 0.00001;
    const curve2 = toVector2(curve);
    const polygon2 = toVector2(polygon);
    /**
     *   |   |--------<----------|  |
     *   |   |-------------------|  |
     *   |   1==<=====<====<=====0  |
     *   |   |-------------------|  |
     *   V   |-------->----------|  V
     */
    const dirCurve = curve2[1].clone().sub(curve2[0]).normalize();
    for (let i = 0; i < polygon2.length - 1; i++) {
        const dirEdge = polygon2[i + 1].clone().sub(polygon2[i]).normalize();
        const dot = dirCurve.dot(dirEdge);
        if (dot < -(1 - THRESHOLD)) {
            return [polygon2[i + 1], polygon2[i]];
        }
    }
    console.warn('No edge is paralel to the curve');
    return [];
};
exports.getInnerEdgeOfPolygon = getInnerEdgeOfPolygon;
const getIndicesForLineGeometry = (points) => {
    return points.flatMap((_, index) => [index, (index + 1) % points.length]);
};
exports.getIndicesForLineGeometry = getIndicesForLineGeometry;
const getIndicesForLinePairs = (points) => {
    return Array(points.length).fill(0).map((_, index) => index);
};
exports.getIndicesForLinePairs = getIndicesForLinePairs;
// https://gist.github.com/maxogden/574870
const doPointInPolygon = (polygon, point) => {
    const polygonInNumbers = polygon.map(p => p.toArray());
    const pointInNumbers = point.toArray();
    let c = false;
    let i = -1;
    const l = polygonInNumbers.length;
    let j = l - 1;
    for (i; ++i < l; j = i) {
        ((polygonInNumbers[i][1] <= pointInNumbers[1] && pointInNumbers[1] < polygonInNumbers[j][1])
            || (polygonInNumbers[j][1] <= pointInNumbers[1] && pointInNumbers[1] < polygonInNumbers[i][1]))
            && pointInNumbers[0]
                < ((polygonInNumbers[j][0] - polygonInNumbers[i][0]) * (pointInNumbers[1] - polygonInNumbers[i][1]))
                    / (polygonInNumbers[j][1] - polygonInNumbers[i][1])
                    + polygonInNumbers[i][0]
            && (c = !c);
    }
    return c;
};
exports.doPointInPolygon = doPointInPolygon;
const getPolylineArea = (polyline) => {
    const poly = toVector2(polyline.toThree());
    return Math.abs(three_1.ShapeUtils.area(poly));
};
exports.getPolylineArea = getPolylineArea;
const getPolygonArea = (polygon) => {
    if (!polygon.boundary) {
        return 0;
    }
    const boundaryArea = (0, exports.getPolylineArea)(polygon.boundary);
    if (!polygon.holes) {
        return boundaryArea;
    }
    const holesArea = polygon.holes.reduce((acc, hole) => acc + (0, exports.getPolylineArea)(hole), 0);
    return boundaryArea - holesArea;
};
exports.getPolygonArea = getPolygonArea;
