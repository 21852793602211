import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { feasibilitySwpProjectModel } from 'utils/model/feasibilitySwpProjectModel';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { getProfileByIdSelector, swappProjectSelector } from 'store/swappProfile';
import ProgramViewer from './ProgramViewer';
import { EditorContextProvider } from './EditorContext';
import ProgramUi from './ProgramUi';

const ProgramWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const SwpProjectProgramContainer = () => {
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const profile = useSelector((state) => getProfileByIdSelector(state, locationData.profileId));
  const project = useSelector(swappProjectSelector);
  const enrichedSwpProject = useMemo(() => feasibilitySwpProjectModel(profile.result.enrichedSwpProject), [profile]);
  const parentProfile = project.projectProfiles.find((pp) => pp.id === profile.parentProfileId);
  const { lat, lng } = project;
  const { elevation } = parentProfile.result;
  const [isOrthographic, setIsOrthographic] = useState(true);
  const [isTechnical, setIsTechnical] = useState(false);
  // const [viewKey, setViewKey] = useState(VIEWER_PRESETS_KEYS.RENDERED);

  return (
    <ProgramWrapper>
      <EditorContextProvider>
        <ProgramViewer
          isOrthographic={isOrthographic}
          isTechnical={isTechnical}
          // viewKey={viewKey}
          // setViewKey={setViewKey}
          enrichedSwpProject={enrichedSwpProject.swpProjectData}
          environmentURL={profile.result.environmentURL}
          locationData={locationData}
          envData={{ lat, lng, elevation }}
        />
        <ProgramUi
          enrichedSwpProject={enrichedSwpProject}
          isOrthographic={isOrthographic}
          setIsOrthographic={setIsOrthographic}
          isTechnical={isTechnical}
          setIsTechnical={setIsTechnical}
        />
      </EditorContextProvider>
    </ProgramWrapper>
  );
};

SwpProjectProgramContainer.propTypes = {};

export default SwpProjectProgramContainer;
