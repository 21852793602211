"use strict";
/*
 * A mix of speckle's EventEmitter with TS + THREE.EventDispatcher
 */
Object.defineProperty(exports, "__esModule", { value: true });
class EventEmitter {
    constructor() {
        this.events = {};
    }
    on(name, listener) {
        if (this.events === null)
            throw new Error('EventEmitter is disposed');
        if (!this.events[name]) {
            this.events[name] = [];
        }
        this.events[name].push(listener);
    }
    remove(name, listenerToRemove) {
        if (this.events === null)
            throw new Error('EventEmitter is disposed');
        if (!this.events[name])
            return;
        const filterListeners = (listener) => listener !== listenerToRemove;
        this.events[name] = this.events[name].filter(filterListeners);
    }
    emit(name, params = {}) {
        if (this.events === null)
            throw new Error('EventEmitter is disposed');
        if (!this.events[name])
            return;
        const fireCallbacks = (callback) => {
            callback({ name, params, target: this });
        };
        this.events[name].forEach(fireCallbacks);
    }
    has(name, listener) {
        if (this.events === null)
            throw new Error('EventEmitter is disposed');
        if (this.events === undefined)
            return false;
        return this.events[name] !== undefined && this.events[name].indexOf(listener) !== -1;
    }
    dispose() {
        this.events = null;
    }
}
exports.default = EventEmitter;
