"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const EDITOR_EVENTS = {
    CAMERA_CHANGED: 'CAMERA_CHANGED',
    TERRAIN_CLICKED: 'TERRAIN_CLICKED',
    BUILDING_CLICKED: 'BUILDING_CLICKED',
    SCENE_OBJECT_CLICKED: 'BUILDING_CLICKED',
    VIEW_CHANGED: 'VIEW_CHANGED',
    ON_ISOLATE: 'ON_ISOLATE',
};
exports.default = EDITOR_EVENTS;
