"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
const Utils_1 = require("../Utils");
class TransformObject extends THREE.Group {
    constructor(swpProduct) {
        super();
        this.swpProduct = swpProduct;
        const { translate, rotate, scale } = (0, Utils_1.swpToThreeTransform)(this.swpProduct.transform);
        this.position.copy(translate);
        this.rotation.setFromQuaternion(rotate);
        this.scale.copy(scale);
        this.matrixWorldNeedsUpdate = true;
        this.updateMatrix();
        this.name = `${this.swpProduct.type}_transformObject_${this.swpProduct.id}`;
    }
}
exports.default = TransformObject;
