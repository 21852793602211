import lodashGet from 'lodash/get';
import lodashIncludes from 'lodash/includes';
import { PROJECT_STAGES } from 'constants/profileConsts';
import { UI_AUTHORIZE_PATH } from 'constants/routes/ui';

export const userProjectsSelector = ({ userProjects }) => {
  if (userProjects.userProjects) {
    return userProjects.userProjects.map((project) => {
      const stage = lodashGet(lodashGet(project, 'projectProfiles', []).find((item) => !item.parentProfileId), 'stage');

      if (lodashIncludes([PROJECT_STAGES[UI_AUTHORIZE_PATH.FEASIBILITY_STUDY], PROJECT_STAGES[UI_AUTHORIZE_PATH.ONE_CLICK_BUILDING], PROJECT_STAGES[UI_AUTHORIZE_PATH.TEST_FIT]], stage)) {
        return { ...project, stage };
      }
    }).filter((e) => e);
  }
  return [];
};
export const isProjectsLoadingSelector = ({ userProjects }) => userProjects.loading || false;
export const inProgressSelector = ({ userProjects }) => userProjects.inProgress || false;
