import React, { useEffect, useState } from 'react';
import Editor from '@swapp/swappcommonjs/dist/editor/Editor';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as THREE from 'three';
import lodashSortBy from 'lodash/sortBy';
import { VIEWER_PRESETS_KEYS } from '@swapp/swappcommonjs/dist/editor/RenderConfig';
import SwpBuildingStory from '@swapp/swappcommonjs/dist/swpProject/spatialProducts/SwpBuildingStory';
import { getDistanceBetweenCoordinates, pointFromDistance } from 'components/projectSettings/setup/ArcGis/ArcGisMapHelpers';
import EDITOR_EVENTS from '@swapp/swappcommonjs/dist/editor/Events/EditorEvents';
import { normalizeAngle } from 'utils/algorithms/algorithmHelpers';
import { useEditor } from './EditorContext';
import { selectedStorySelector } from '../../../../../store/BuildingStoriesStore';

const ProgramViewer = ({ isOrthographic, isTechnical, enrichedSwpProject, environmentURL, locationData, envData }) => {
  const { setEditor, editor } = useEditor();
  const selectedStory = useSelector(selectedStorySelector);
  const [isEditorInitialize, setIsEditorInitialize] = useState(false);
  const viewKey = isTechnical ? VIEWER_PRESETS_KEYS.TECHNICAL : VIEWER_PRESETS_KEYS.RENDERED;

  useEffect(() => {
    if (!editor) {
      setEditor(new Editor(document.getElementById('editor')));
      return;
    }
    initializeProfile();
  }, [editor, locationData.profileId]);

  useEffect(() => {
    editor?.toggleCameraByValue(isOrthographic);
    adjustFloors();
  }, [isOrthographic]);

  useEffect(() => {
    editor?.changeView(viewKey);
    adjustFloors();
  }, [isTechnical]);

  useEffect(() => {
    adjustFloors();
  }, [selectedStory]);

  const adjustFloors = () => {
    if (!editor) {
      return;
    }
    if (selectedStory == null) {
      editor.isolateBuildingStories();
      return;
    }
    const stories = getStoriesByLevel();
    const storiesIdsToShow = stories.filter((story) => story.level <= selectedStory).map((story) => story.story.id);
    editor.isolateBuildingStories(storiesIdsToShow);
  };

  const getStoriesByLevel = () => {
    const swpStories = enrichedSwpProject.site.childrenByType(SwpBuildingStory, true);
    const sortedStories = lodashSortBy(swpStories, [(story) => story.transform.position.z]);
    const belowGroundFloors = sortedStories.map((story) => (story.transform.position.z < 0 ? story : null)).filter((e) => e);
    const aboveGroundFloors = sortedStories.map((story) => (story.transform.position.z >= 0 ? story : null)).filter((e) => e);
    const stories = [];
    belowGroundFloors.forEach((story, index) => stories.push({ level: -(belowGroundFloors.length - index), story }));
    aboveGroundFloors.forEach((story, index) => stories.push({ level: index, story }));

    return stories;
  };

  const getSiteGeographicalTransform = async () => {
    const ccwAngleToNorth = enrichedSwpProject.site.getProperty('ccw_angle_to_north') || 0;
    const siteElevation = enrichedSwpProject.site.getProperty('elevation') || 0;
    const latitude = enrichedSwpProject.site.getProperty('latitude');
    const longitude = enrichedSwpProject.site.getProperty('longitude');

    if (!latitude || !longitude) {
      return;
    }

    const offset = new THREE.Vector2();
    const offsetX = await getDistanceBetweenCoordinates({ x: longitude, y: latitude }, { x: longitude, y: envData.lat }) || 0;
    const offsetY = await getDistanceBetweenCoordinates({ x: longitude, y: latitude }, { x: envData.lng, y: latitude }) || 0;

    offset.y = -offsetX.distance * Math.cos(offsetX.azimuth * (Math.PI / 180));
    offset.x = offsetY.distance * Math.sin(offsetY.azimuth * (Math.PI / 180));

    const translation = new THREE.Vector3(offset.x, offset.y, siteElevation - (envData.elevation || 0));
    const rotation = new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), ccwAngleToNorth);

    return { translation, rotation };
  };

  const showCoordinates = async (point) => {
    console.log(point);
    const newElev = point.z + (envData.elevation || 0);
    const azimuth = normalizeAngle(-Math.atan2(point.x, point.y) * (180 / Math.PI));
    const dist = new THREE.Vector2(point.x, point.y).length();
    const newPoint = await pointFromDistance({ x: envData.lng, y: envData.lat }, dist, azimuth);
    console.log('Enter this in Revit:');
    console.log(`lat: ${newPoint.y}, lng: ${newPoint.x}, elavation: ${newElev}`);
  };

  const initializeProfile = async () => {
    setIsEditorInitialize(true);
    const offsetProps = await getSiteGeographicalTransform();

    editor.addSwpProject(enrichedSwpProject, offsetProps);
    editor.addEnvironment(environmentURL);

    editor.changeView(viewKey);
    adjustFloors();
    if (!isEditorInitialize) {
      editor.fitToView();
    }

    editor.on(EDITOR_EVENTS.TERRAIN_CLICKED, ({ params }) => {
      showCoordinates(params.intersection.point);
    });
  };

  return (
    <div id="editor" style={{ width: '100%', height: '100%', border: '0 solid', position: 'relative' }} />
  );
};

ProgramViewer.propTypes = {
  isOrthographic: PropTypes.bool,
  isTechnical: PropTypes.bool,
  enrichedSwpProject: PropTypes.object,
  environmentURL: PropTypes.string,
  locationData: PropTypes.object,
  envData: PropTypes.object,
};

export default ProgramViewer;
