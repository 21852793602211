import React, { useMemo } from 'react';
import T from 'i18n-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import lodashGet from 'lodash/get';
import styled from 'styled-components';
import InfoBox from 'components/common/InfoBox';
import { sqfToSqm } from 'utils/helpers/unitsHelpers';
import { numberWithComma } from 'utils/helpers/dataDisplay';
import { parseLocationUrl } from 'utils/helpers/navigationHelpers';
import { getUnitSystemByProjectIdSelector } from 'store/userSettings';
import ViewerUiPanel from 'utils/swappViewer/components/ui/ViewerUiPanel';
import BuildingStoryUI from 'utils/swappViewer/components/Stories/BuildingStoryUI';
import { ViewerUiPanelWrapper, UiButtonsWrapper } from 'styles/commonComponents.styles';
import CameraProjectionToggleButton from 'utils/swappViewer/components/ui/CameraProjectionToggleButton';
import SwappViewerActionButton from 'utils/swappViewer/components/ui/SwappViewerActionButton';
import icons from '../../../../../styles/static/icons/viewerIcons';

const infoBoxOptions = { marginBottom: 10, marginLeft: 10, marginRight: 10, width: 200 };

const BoxSeparator = styled.div`
  width: 420px;
  margin: 10px 10px 20px 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray_04};
`;

const ProgramUi = (props) => {
  const { isOrthographic, setIsOrthographic, isTechnical, setIsTechnical, enrichedSwpProject } = props;
  const history = useHistory();
  const locationData = parseLocationUrl(history.location);
  const isImperial = useSelector((state) => getUnitSystemByProjectIdSelector(state, locationData.projectId));

  const areaOptions = useMemo(() => ({ fixed: isImperial ? 0 : 1, suffix: ` ${T.translate(isImperial ? 'SQF' : 'SQM')}` }), [isImperial]);
  const getImperialArea = (area) => (isImperial ? area : sqfToSqm(area));

  return (
    <>
      <ViewerUiPanelWrapper position="top-left" fitContent>
        <ViewerUiPanel width={450} wrap>
          <InfoBox
            {...infoBoxOptions}
            data={{ title: 'Primary Area', rightInfo: numberWithComma(getImperialArea(enrichedSwpProject.storiesArea), areaOptions) }}
          />
          <InfoBox
            {...infoBoxOptions}
            data={{ title: 'Stories', rightInfo: enrichedSwpProject.storiesAmount.join(' | ') }}
          />
          <InfoBox
            {...infoBoxOptions}
            data={{ title: 'Core', rightInfo: numberWithComma(getImperialArea(enrichedSwpProject.coresArea), areaOptions) }}
          />
          <BoxSeparator />
          {enrichedSwpProject.unitData.map((unit) => (
            <InfoBox
              {...infoBoxOptions}
              data={{
                title: T.translate(unit.value),
                rightInfo: numberWithComma(getImperialArea(unit.area), areaOptions),
                leftInfo: unit.unitAmount,
                color: unit.color,
              }}
            />
          ))}
          <InfoBox
            {...infoBoxOptions}
            width={undefined}
            isTotal
            data={{
              title: T.translate(enrichedSwpProject.unitDataTotal.value),
              rightInfo: numberWithComma(getImperialArea(enrichedSwpProject.unitDataTotal.area), areaOptions),
              leftInfo: enrichedSwpProject.unitDataTotal.unitAmount,
            }}
          />
        </ViewerUiPanel>

        {/* ========= Ui Buttons ========= */}
        <UiButtonsWrapper>
          {/* ========= toggle Orthographic ========= */}
          <CameraProjectionToggleButton
            toggle={() => setIsOrthographic(!isOrthographic)}
            selected={!isOrthographic}
            marginTop={10}
          />
          {/* ========= toggle Render/Technical View ========= */}
          <SwappViewerActionButton onClick={() => setIsTechnical(!isTechnical)} icon={icons.renderIcon} selected={!isTechnical} marginTop={10} />
        </UiButtonsWrapper>
      </ViewerUiPanelWrapper>

      {/* ============== Building stories ============== */}
      <ViewerUiPanelWrapper position="bottom-right" fitContent>
        <ViewerUiPanel width={150} isRight>
          <BuildingStoryUI swpProject={lodashGet(enrichedSwpProject, 'swpProjectData')} />
        </ViewerUiPanel>
      </ViewerUiPanelWrapper>
    </>
  );
};

ProgramUi.propTypes = {
  isOrthographic: PropTypes.bool,
  isTechnical: PropTypes.bool,
  setIsOrthographic: PropTypes.func,
  setIsTechnical: PropTypes.func,
  enrichedSwpProject: PropTypes.object,
};

export default ProgramUi;
