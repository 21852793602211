"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.linesToFrameGeometry = exports.extrudeSegmentLines = exports.getAncestorSceneObjectByType = exports.getParentSceneObject = exports.createThreeShape = exports.geomToThreeShape = exports.swpToThreeTransform = void 0;
const three_1 = require("three");
const Vector3_1 = require("three/src/math/Vector3");
const THREE = __importStar(require("three"));
const MergeBufferGeometries_1 = require("../utils/MergeBufferGeometries");
const swpToThreeTransform = (transform) => {
    const translate = new Vector3_1.Vector3();
    const rotate = new three_1.Quaternion();
    const scale = new Vector3_1.Vector3();
    if (transform === null || transform === void 0 ? void 0 : transform.position) {
        translate.set(transform.position.x, transform.position.y, transform.position.z);
    }
    if (transform === null || transform === void 0 ? void 0 : transform.rotation) {
        rotate.set(transform.rotation.x, transform.rotation.y, transform.rotation.z, transform.rotation.w);
    }
    else {
        rotate.identity();
    }
    if (transform === null || transform === void 0 ? void 0 : transform.scale) {
        scale.set(transform.scale.x, transform.scale.y, transform.scale.z);
    }
    else {
        scale.set(1, 1, 1);
    }
    return { translate, rotate, scale };
};
exports.swpToThreeTransform = swpToThreeTransform;
const geomToThreeShape = (swpGeom) => {
    var _a, _b;
    if (!swpGeom) {
        return;
    }
    const geom = swpGeom.saveToObject();
    let shape;
    if ((_a = geom === null || geom === void 0 ? void 0 : geom.boundary) === null || _a === void 0 ? void 0 : _a.length) {
        shape = new THREE.Shape(geom === null || geom === void 0 ? void 0 : geom.boundary.map((boundary) => new THREE.Vector2(...boundary)));
    }
    if (shape && ((_b = geom === null || geom === void 0 ? void 0 : geom.holes) === null || _b === void 0 ? void 0 : _b.length)) {
        geom === null || geom === void 0 ? void 0 : geom.holes.forEach((hole) => {
            shape.holes.push(new THREE.Path(hole.map((boundary) => new THREE.Vector2(...boundary))));
        });
    }
    return shape;
};
exports.geomToThreeShape = geomToThreeShape;
const createThreeShape = (boundary, holes) => {
    const shape = new THREE.Shape(boundary);
    if (holes) {
        holes.forEach((hole) => {
            shape.holes.push(new THREE.Path(hole));
        });
    }
    return shape;
};
exports.createThreeShape = createThreeShape;
const getParentSceneObject = (object) => {
    let parent = object;
    while (!parent.swpProduct) {
        parent = parent.parent;
        if (!parent) {
            break;
        }
    }
    return parent;
};
exports.getParentSceneObject = getParentSceneObject;
const getAncestorSceneObjectByType = (object, type) => {
    let parent = object;
    while (!!parent && !(parent.swpProduct instanceof type)) {
        parent = parent === null || parent === void 0 ? void 0 : parent.parent;
    }
    return parent;
};
exports.getAncestorSceneObjectByType = getAncestorSceneObjectByType;
const extrudeSegmentLines = (segment, height, bottom) => {
    const lines = [
        new THREE.LineCurve3(new THREE.Vector3(segment[0].x, segment[0].y, bottom), new THREE.Vector3(segment[1].x, segment[1].y, bottom)),
        new THREE.LineCurve3(new THREE.Vector3(segment[1].x, segment[1].y, bottom), new THREE.Vector3(segment[1].x, segment[1].y, height)),
        new THREE.LineCurve3(new THREE.Vector3(segment[1].x, segment[1].y, height), new THREE.Vector3(segment[0].x, segment[0].y, height)),
        new THREE.LineCurve3(new THREE.Vector3(segment[0].x, segment[0].y, height), new THREE.Vector3(segment[0].x, segment[0].y, bottom)),
    ];
    lines.forEach((line) => line.arcLengthDivisions = 4);
    return lines;
};
exports.extrudeSegmentLines = extrudeSegmentLines;
const linesToFrameGeometry = (lines, thickness, modifyObject) => {
    // Returns a geometry with a 'ח ' shape
    const boxes = [];
    lines.forEach((line) => {
        let boxWidth = (modifyObject === null || modifyObject === void 0 ? void 0 : modifyObject.x) || thickness;
        let boxHeight = (modifyObject === null || modifyObject === void 0 ? void 0 : modifyObject.y) || thickness;
        let boxDepth = (modifyObject === null || modifyObject === void 0 ? void 0 : modifyObject.z) || thickness;
        if (line.v1.x !== line.v2.x) {
            boxWidth = Math.abs(line.v1.x - line.v2.x) + thickness;
        }
        else if (line.v1.y !== line.v2.y) {
            boxHeight = Math.abs(line.v1.y - line.v2.y) + thickness;
        }
        else {
            boxDepth = Math.abs(line.v1.z - line.v2.z) + thickness;
        }
        const box = new THREE.BoxBufferGeometry(boxWidth, boxHeight, boxDepth);
        const mid = line.v1.add(line.v2).divideScalar(2);
        box.translate(mid.x, mid.y, mid.z);
        boxes.push(box);
    });
    return (0, MergeBufferGeometries_1.mergeBufferGeometries)(boxes);
};
exports.linesToFrameGeometry = linesToFrameGeometry;
