"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const THREE = __importStar(require("three"));
class LightsManager {
    constructor(editor) {
        this.lights = [];
        this.editor = editor;
        this.addRenderLights();
    }
    addTechnicalLights() {
        this.clearLights();
        const ambientLight = new THREE.AmbientLight('#ffffff', 1);
        this.addLight(ambientLight);
        const skyColor = '#ffffff';
        const groundColor = '#7c7c91';
        const hemisphereLight = new THREE.HemisphereLight(skyColor, groundColor, 0.65);
        this.addLight(hemisphereLight);
    }
    addRenderLights() {
        this.clearLights();
        const ambientLight = new THREE.AmbientLight('#ccd3ff', 0.3);
        this.addLight(ambientLight);
        const skyColor = '#eff8ff';
        const groundColor = '#6e6962';
        const hemisphereLight = new THREE.HemisphereLight(skyColor, groundColor, 0.5);
        this.addLight(hemisphereLight);
        const positionMultiplayer = 1.5;
        const shadowBoxResolution = 8192;
        const directionalLight = new THREE.DirectionalLight('#ffffff', 1.3);
        directionalLight.castShadow = true;
        directionalLight.position.set(-50 * positionMultiplayer, -120 * positionMultiplayer, 128 * positionMultiplayer);
        directionalLight.lookAt(0, 0, 0);
        directionalLight.shadow.mapSize.width = shadowBoxResolution;
        directionalLight.shadow.mapSize.height = shadowBoxResolution;
        directionalLight.shadow.radius = 1; // soften the shadow - default is 1.0. if this.renderer.shadowMap.type = THREE.PCFSoftShadowMap; radius is disabled
        directionalLight.shadow.camera.near = 0.5;
        directionalLight.shadow.camera.far = 350;
        directionalLight.shadow.camera.left = -100;
        directionalLight.shadow.camera.right = 100;
        directionalLight.shadow.camera.top = 100;
        directionalLight.shadow.camera.bottom = -50;
        this.addLight(directionalLight);
        // light helper for QA
        // const directionalLightHelper = new THREE.DirectionalLightHelper(directionalLight);
        // this.addLight(directionalLightHelper)
        // const cameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera);
        // this.editor.scene.add(cameraHelper);
    }
    clearLights() {
        this.lights.forEach((light) => {
            this.editor.scene.remove(light);
        });
        this.lights = [];
    }
    addLight(light) {
        this.editor.scene.add(light);
        this.lights.push(light);
        return light;
    }
}
exports.default = LightsManager;
